import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModBuscandoDatos from "../../../components/Chronology/Modules/ModBuscandoDatos";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 93",
  week: "Semana 14",
  day: "14",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-14",
  path: "/cronologia/semana-14#dia-14-jun/",
};

const Day93 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 1.737 casos mediante pruebas PCR. En ese mismo
          periodo, 110 personas han requerido hospitalización (6,3% de los
          diagnósticos), de los que cinco han sido ingresos en la UCI, y se han
          producido 26 fallecimientos.
        </ModText>
        <ModBuscandoDatos num="random" />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day93;
